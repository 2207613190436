import { RouterScrollBehavior, createMemoryHistory, createRouter, createWebHistory } from 'vue-router';

import routes from './routes';

const scrollBehavior: RouterScrollBehavior = (to, _from, savedPosition) => {
    if (savedPosition) return savedPosition;

    if (to.hash) {
        return {
            el: to.hash
        };
    }

    return { top: 0 };
};

export default function () {
    const routerHistory = import.meta.env.SSR === false ? createWebHistory() : createMemoryHistory();
    const router = createRouter({
        history: routerHistory,
        routes,
        scrollBehavior
    });

    router.afterEach((to, from, failure) => {
        if (failure) {
            console.log(to, from, failure);
        }
    });

    return router;
}
