import { origin } from '@/utils/constant';
import initCookiesPlugin from '@/utils/cookies';
import * as Sentry from '@sentry/vue';
import Rollbar from 'rollbar';
import { SEvernote, SInstaPaper, SLinkedIn, SPocket, STrello, STwitter } from 'vue-socials';

import { createApp } from './main';

initCookiesPlugin();

interface Location {
    [x: string]: any;
    assign(href: string | URL): void;
}

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const isProd = import.meta.env.MODE === 'production';
const isBuildEnv = import.meta.env.VITE_ENV_MODE === 'production';

const rollbar = new Rollbar({
    accessToken: '5fe226b34a6d4af19567178b1b717d47',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: isProd ? 'production' : 'dev',
        client: {
            javascript: {
                code_version: '1.0.0'
            }
        }
    }
});

createApp().then(async ({ app, router }) => {
    // wait client side hydration to complete
    await router.isReady();

    if (typeof window !== 'undefined') {
        let location: Location = window.location;

        router.onError((error: { message: string | string[] }, to: { fullPath: string }) => {
            const errors = [
                'Failed to fetch dynamically imported module',
                'Unable to preload CSS',
                'Importing a module script failed'
            ];

            if (errors.some((e) => error.message.includes(e))) {
                if (!to?.fullPath) {
                    location.reload();
                } else {
                    window.location.href = to.fullPath;
                }
            }
        });

        window.addEventListener('vite:preloadError', () => {
            location.reload();
        });

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register(import.meta.env.MODE === 'production' ? `${origin}/sw.js` : '/dev-sw.js?dev-sw');
        }
    }

    Sentry.init({
        app,
        dsn: SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false
            })
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/advisorycxo\.com\//, /^https:\/\/staging.advisorycxo\.com\//],
        // Session Replay
        replaysSessionSampleRate: isBuildEnv ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    app.config.errorHandler = (err: any, vm: any, info: any) => {
        rollbar.error(err, { vueComponent: vm, info });
        throw err; // rethrow
    };

    app.provide('rollbar', rollbar);
    app.component('STrello', STrello);
    app.component('SEvernote', SEvernote);
    app.component('SInstaPaper', SInstaPaper);
    app.component('SPocket', SPocket);
    app.component('SLinkedIn', SLinkedIn);
    app.component('STwitter', STwitter);

    app.mount('#app');
});
