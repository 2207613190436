<template>
    <teleport :to="target">
        <div v-if="showBanner" class="fixed z-[200] px-6 py-4 bottom-0 left-0 right-0">
            <div class="max-w-[1168px] w-full xl:w-[90%] mx-auto">
                <div
                    class="flex flex-col md:flex-row items-center justify-between gap-y-4 gap-x-8 p-5 rounded-lg bg-white shadow-2xl border border-black--10--rgba">
                    <div>
                        <h3 class="font-semibold">Cookie settings</h3>

                        <p class="text-sm">
                            We use cookies and similar technologies to help personalize content and offer a better experience. You
                            can opt to customize them by clicking the preferences button.
                        </p>
                    </div>

                    <div class="mx-auto md:ml-auto flex gap-2">
                        <button class="button outline whitespace-nowrap" @click="handlePreferences">Preference</button>
                        <button class="button primary rounded-full whitespace-nowrap" @click="acceptCookie">Accept All</button>
                    </div>
                </div>

                <transition leave-active-class="duration-200">
                    <div v-show="showModal" class="fixed inset-0 overflow-hidden px-4 py-6 sm:px-0 z-[1000] flex items-center">
                        <transition
                            enter-active-class="ease-out duration-300"
                            enter-from-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="ease-in duration-200"
                            leave-from-class="opacity-100"
                            leave-to-class="opacity-0">
                            <div v-show="showModal" class="fixed inset-0 transform transition-all">
                                <div class="absolute inset-0 w-full h-full bg-dark--800--lighten opacity-40"></div>
                            </div>
                        </transition>

                        <transition
                            enter-active-class="ease-out duration-300"
                            enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                            leave-active-class="ease-in duration-200"
                            leave-from-class="opacity-100 translate-y-0 sm:scale-100"
                            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <div v-show="showModal" class="sm:max-w-xl sm:w-full sm:mx-auto transform transition-all shadow-2xl">
                                <div class="relative p-6 bg-white rounded-lg">
                                    <div class="absolute top-6 right-6">
                                        <button
                                            class="text-danger--dark-color hover:text-white border border-danger--dark-color bg-danger--light-color hover:bg-danger--dark-color focus:ring-danger--light-color p-1.5 text-sm font-medium focus:outline-none rounded-md focus:z-10 focus:ring-4 transition duration-150 ease-in-out"
                                            type="button"
                                            @click="handleModalClose">
                                            <svg
                                                class="h-[12px] w-[12px]"
                                                viewPort="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <line stroke="currentColor" stroke-width="2" x1="1" x2="11" y1="11" y2="1" />
                                                <line stroke="currentColor" stroke-width="2" x1="1" x2="11" y1="1" y2="11" />
                                            </svg>
                                        </button>
                                    </div>

                                    <div>
                                        <h3 class="text-xl font-medium mb-2">Your privacy preferences</h3>
                                        <p class="text-sm mb-4 pr-10">
                                            We use cookies to improve your experience. These cookies are mandatory for this site
                                            to work properly. By using our site you agree with our Cookie Policy.
                                        </p>
                                        <hr class="border-t-2 border-[var(--card-border-color)]" />
                                    </div>

                                    <div class="py-6 pr-4 max-h-96 overflow-y-auto">
                                        <div v-for="(preference, index) in preferences" :key="index">
                                            <h5 class="text-semibold">
                                                {{ preference.title }}
                                            </h5>

                                            <p class="text-sm opacity-80">
                                                {{ preference.description }}
                                            </p>

                                            <div class="space-y-2 mt-6">
                                                <div
                                                    v-for="item in preference.items"
                                                    :key="item.value"
                                                    class="flex justify-between items-center">
                                                    <h6>{{ item.label }}</h6>
                                                    <div class="switch">
                                                        <input
                                                            :id="`toggle_${item.value}`"
                                                            :checked="item.isRequired"
                                                            :disabled="item.isRequired"
                                                            :value="item.value"
                                                            class="sr-only peer"
                                                            type="checkbox"
                                                            @input="onChange(item.value)" />

                                                        <label
                                                            :class="{
                                                                'cursor-pointer': !item.isRequired,
                                                                'cursor-not-allowed': item.isRequired
                                                            }"
                                                            :for="`toggle_${item.value}`">
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr
                                                v-if="index !== preferences.length - 1"
                                                class="border-t border-[var(--card-border-color)] my-6" />
                                        </div>
                                    </div>

                                    <div>
                                        <hr class="border-t-2 border-[var(--card-border-color)]" />
                                        <div class="flex items-center justify-between pt-6">
                                            <button v-if="!hasOnlyRequiredCookies" class="button danger" @click="declineCookie">
                                                Decline All
                                            </button>
                                            <button class="button primary" @click="acceptCookie">Accept All</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </transition>
            </div>
        </div>
    </teleport>
</template>
<script setup lang="ts">
import { useUniversalCookies } from '@/utils/cookies';
import { useScript } from '@unhead/vue';
import { useStorage } from '@vueuse/core';

const target = 'body';
const consentName = 'CookieConsent';
const daysToLive = 90;

const preferences = [
    {
        title: 'Session token (necessary)',
        description:
            'This cookie contains a token. With the help of the token, your current session can be continued when using the website. This cookie is valid for 2 hours after creation and is renewed each time you change pages within this website.',
        items: [
            {
                label: '*_session',
                value: 'session',
                isRequired: true
            }
        ]
    },
    {
        title: 'Remember Token',
        description:
            'This cookie contains a token. With the help of the token, your login session can be resumed even after the 2 hours that the session cookie is valid. This cookie is only set if you have activated the option "Stay logged in" when logging in. This cookie is removed when you log out.',
        items: [
            {
                label: 'remember_web_*',
                value: 'remember_web',
                isRequired: true
            }
        ]
    },
    {
        title: 'XSRF Token',
        description:
            'This cookie contains a token. The token prevents a third party from submitting forms on the website on your behalf. This cookie is valid for 2 hours and is renewed at every page change within the website.',
        items: [
            {
                label: 'XSRF-TOKEN',
                value: 'xsrf_token',
                isRequired: true
            }
        ]
    },
    {
        title: 'Google Analytics',
        description: 'Google Analytics stores this cookie to track your usage of the website. Your IP address is not stored.',
        items: [
            {
                label: '_ga',
                value: 'ga',
                isRequired: false
            }
        ]
    }
];

const showBanner = ref(false);
const showModal = ref(false);
const checkedValues = ref([]);

const localStorage = useStorage(consentName, null as string | object | null | undefined);
const cookies = useUniversalCookies([consentName]);

const { gtag } = useScript<any>(
    {
        async: true,
        src: 'https://www.google-analytics.com/analytics.js'
    },
    {
        trigger: 'manual', // loads the script on requestIdleCallback hook
        use: () => ({ gtag: (window as any).gtag })
    }
);

const requiredCookies = computed(() => {
    return preferences
        .map((preference) => preference.items.map((item) => (item.isRequired ? item.value : null)))
        .flat()
        .filter((n) => n);
});

const allCookies = computed(() => {
    return preferences
        .map((preference) => preference.items.map((item) => item.value))
        .flat()
        .filter((n) => n);
});

const hasOnlyRequiredCookies = computed(() => {
    return requiredCookies.value.length === preferences.length;
});

const acceptCookie = () => {
    const date = new Date();
    showBanner.value = false;
    showModal.value = false;
    localStorage.value = JSON.stringify(allCookies.value);
    cookies.set(consentName, `${date}+${allCookies.value}`, {
        path: '/',
        maxAge: daysToLive * 24 * 60 * 60,
        sameSite: 'lax'
    });

    gtag('consent', 'update', {
        ad_storage: 'granted'
    });
};

const declineCookie = () => {
    showBanner.value = false;
    showModal.value = false;
    localStorage.value = JSON.stringify(requiredCookies.value);

    // router.push('/cookies');
};

const handlePreferences = () => (showModal.value = true);

const handleModalClose = () => (showModal.value = false);

const onChange = (value: any) => {
    checkedValues.value = value;
};

if (typeof cookies.get(consentName) === 'undefined') {
    showBanner.value = true;
} else {
    showBanner.value = false;
}
</script>
