<template>
    <header
        v-if="!$route.meta.hidePageLayout"
        class="fixed top-0 left-0 right-0 bg-[var(--dark)] lg:flex lg:items-stretch"
        :class="isPopoverOpen ? 'z-[50]' : 'z-[200]'">
        <div class="container">
            <div class="flex items-center flex-wrap justify-between md:flex-nowrap md:flex-start h-20">
                <div>
                    <router-link to="/" aria-label="Home Page">
                        <LogoIcon class="max-w-32 sm:max-w-[150px] md:max-w-[180px] w-full h-auto" />
                    </router-link>
                </div>

                <nav class="flex items-center self-stretch">
                    <ul v-if="data?.navigation_items.length > 0" class="hidden md:flex items-center font-light text-base h-full">
                        <template v-for="item in data?.navigation_items" :key="item.id">
                            <template v-if="item.navigation_links.length > 0">
                                <li
                                    v-for="route in item.navigation_links"
                                    :key="route.id"
                                    class="relative text-base font-normal self-stretch">
                                    <template v-if="!!!route.page">
                                        <template v-if="route.internalUrl">
                                            <component
                                                :is="$route.path === `/${route.internalUrl}` ? 'span' : 'router-link'"
                                                :target="route.newTab ? '_blank' : null"
                                                :rel="route.newTab ? 'noopener noreferrer' : null"
                                                :to="$route.path === `/${route.internalUrl}` ? '' : getCurrentUrl(route)"
                                                class="navbar-item"
                                                :class="{ active: $route.path === `/${route.internalUrl}` }">
                                                {{ route.name }}

                                                <ul v-if="route.navigation_links.length > 0" class="navbar-item__sub">
                                                    <li v-for="nested in route.navigation_links" :key="nested.id">
                                                        <router-link
                                                            v-if="nested.internalUrl"
                                                            :target="nested.newTab ? '_blank' : null"
                                                            :rel="nested.newTab ? 'noopener noreferrer' : null"
                                                            :to="{
                                                                name: nested.internalUrl
                                                            }"
                                                            replace>
                                                            {{ nested.name }}
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </component>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <router-link
                                            v-if="route.internalUrl"
                                            :target="route.newTab ? '_blank' : null"
                                            :rel="route.newTab ? 'noopener noreferrer' : null"
                                            :to="getCurrentUrl(route)"
                                            class="navbar-item">
                                            {{ route.name }}
                                        </router-link>
                                    </template>
                                </li>
                            </template>
                        </template>
                    </ul>

                    <div class="hidden md:flex items-center gap-x-3 ml-4">
                        <template v-if="user.isLoggedIn">
                            <router-link
                                :to="
                                    user.user?.username
                                        ? {
                                              name: 'user',
                                              params: {
                                                  username: user.user?.username
                                              }
                                          }
                                        : ''
                                "
                                class="btn bg-white border-white inline-flex items-center gap-x-2 h-10 !px-[12px] text-gray-900">
                                <span
                                    class="inline-flex items-center justify-center font-normal text-foreground select-none shrink-0 bg-secondary overflow-hidden h-7 w-7 text-xs rounded-full">
                                    <img
                                        role="img"
                                        :src="profileAvatarUrl"
                                        class="h-full w-full object-cover"
                                        alt="profile avatar" />
                                </span>

                                <span v-if="user.user && user.user?.profile" class="capitalize mr-1.5 line-clamp-1 font-medium">
                                    {{ user.user?.profile?.first_name }}'s profile
                                </span>
                                <span v-else class="font-medium"> Profile name </span>
                            </router-link>
                            <button class="button primary h-10" @click="userLogout">
                                <span class="text-gray-900 font-semibold">Log out</span>
                            </button>
                        </template>
                        <template v-else>
                            <router-link to="/login" class="block py-2 px-3 text-white font-medium">Login</router-link>
                            <router-link :to="{ name: 'register' }" class="button primary">
                                <span class="text-gray-900 font-semibold">Join for free</span>
                            </router-link>
                        </template>
                    </div>

                    <div class="flex md:hidden items-center h-full">
                        <button class="navbar-toggle" :class="{ open: isOpen }" @click="isOpen = !isOpen">
                            <span></span>
                        </button>
                    </div>
                </nav>
            </div>
        </div>

        <nav
            v-if="isOpen"
            class="absolute md:static top-20 left-0 right-0 block md:hidden bg-white border-b border-[var(--navbar-border-color)] shadow-[#00000014_0_1px_12px]">
            <div class="container">
                <ul v-if="data?.navigation_items.length > 0" class="text-center text-base font-light h-full py-2">
                    <template v-if="data?.navigation_items.length > 0">
                        <template v-for="item in data?.navigation_items" :key="item.id">
                            <li v-for="route in item.navigation_links" :key="route.id" class="text-base font-normal self-stretch">
                                <div v-if="route.navigation_links.length > 0" class="block py-2 px-3 text-dark">
                                    <div class="inline-flex items-center justify-center" @click="isOpenSubmenu = !isOpenSubmenu">
                                        <span class="relative">
                                            {{ route.name }}

                                            <svg
                                                v-if="route.navigation_links.length > 0"
                                                class="absolute top-1 -right-5 w-3.5 h-3.5"
                                                :class="{ 'rotate-180': isOpenSubmenu }"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512">
                                                <path
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="48"
                                                    d="M112 184l144 144 144-144" />
                                            </svg>
                                        </span>
                                    </div>

                                    <template v-if="!!!route.page">
                                        <ul v-show="isOpenSubmenu" class="pt-1 text-sm">
                                            <li>
                                                <router-link
                                                    :target="route.newTab ? '_blank' : null"
                                                    :rel="route.newTab ? 'noopener noreferrer' : null"
                                                    :to="{ name: route.internalUrl }"
                                                    class="block py-0.5"
                                                    @click="isOpen = false">
                                                    Overview
                                                </router-link>
                                            </li>
                                            <template v-if="route.navigation_links.length > 0">
                                                <li v-for="nested in route.navigation_links" :key="nested.id">
                                                    <router-link
                                                        v-if="nested.internalUrl"
                                                        :target="nested.newTab ? '_blank' : null"
                                                        :rel="nested.newTab ? 'noopener noreferrer' : null"
                                                        :to="{
                                                            name: nested.internalUrl
                                                        }"
                                                        class="block py-0.5"
                                                        @click="isOpen = false">
                                                        {{ nested.name }}
                                                    </router-link>
                                                </li>
                                            </template>
                                        </ul>
                                    </template>
                                </div>
                                <router-link
                                    v-else
                                    :target="route.newTab ? '_blank' : null"
                                    :rel="route.newTab ? 'noopener noreferrer' : null"
                                    :to="getCurrentUrl(route)"
                                    class="block py-2 px-3 text-dark"
                                    @click="isOpen = false">
                                    <span>{{ route.name }}</span>
                                </router-link>
                            </li>
                        </template>
                    </template>

                    <template v-if="user.isLoggedIn">
                        <li class="self-stretch mb-2">
                            <router-link
                                :to="
                                    user.user?.username
                                        ? {
                                              name: 'user',
                                              params: {
                                                  username: user.user?.username
                                              }
                                          }
                                        : ''
                                "
                                class="btn bg-white border-white inline-flex items-center gap-x-2 h-10 !px-[12px] text-gray-900">
                                <span
                                    class="inline-flex items-center justify-center font-normal text-foreground select-none shrink-0 bg-secondary overflow-hidden h-7 w-7 text-xs rounded-full">
                                    <img
                                        role="img"
                                        :src="profileAvatarUrl"
                                        class="h-full w-full object-cover"
                                        alt="profile avatar" />
                                </span>

                                <span v-if="user.user && user.user?.profile" class="capitalize mr-1.5 line-clamp-1 font-medium">
                                    {{ user.user?.username }}'s profile
                                </span>
                                <span v-else class="font-medium"> Profile name </span>
                            </router-link>
                        </li>
                        <li class="self-stretch">
                            <button class="button primary" @click="userLogout">Log out</button>
                        </li>
                    </template>
                    <template v-else>
                        <li class="text-base font-normal self-stretch mb-2">
                            <router-link to="/login" class="block py-2 px-3 text-dark">Login</router-link>
                        </li>
                        <li class="self-stretch">
                            <router-link :to="{ name: 'register' }" class="button primary"> Join for free </router-link>
                        </li>
                    </template>
                </ul>
            </div>
        </nav>
    </header>
</template>
<script setup lang="ts">
import LogoIcon from '@/assets/icons/advisorycxo-with-icon.svg';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { useUserStore } from '@/store/user';

interface Props {
    data: {
        id: string | number;
        name: string;
        slug: string;
        navigation_items: [
            {
                id: string | number;
                name: string;
                navigation_links: [
                    {
                        id: string | number;
                        name: string;
                        newTab: boolean;
                        internalUrl: string;
                        externalUrl: string;
                        icon: {
                            url: string;
                        };
                        page: {
                            slug: string | null;
                        };
                        navigation_links: [
                            {
                                id: string | number;
                                name: string;
                                newTab: boolean;
                                internalUrl: string;
                                externalUrl: string;
                                icon: {
                                    url: string;
                                };
                                page: {
                                    slug: string | null;
                                };
                            }
                        ];
                    }
                ];
            }
        ];
    };
}

withDefaults(defineProps<Props>(), {
    data: (): Props['data'] => {
        return {
            id: '',
            name: '',
            slug: '',
            navigation_items: [
                {
                    id: '',
                    name: '',
                    navigation_links: [
                        {
                            id: '',
                            name: '',
                            newTab: false,
                            internalUrl: '',
                            externalUrl: '',
                            icon: {
                                url: ''
                            },
                            page: {
                                slug: null
                            },
                            navigation_links: [
                                {
                                    id: '',
                                    name: '',
                                    newTab: false,
                                    internalUrl: '',
                                    externalUrl: '',
                                    icon: {
                                        url: ''
                                    },
                                    page: {
                                        slug: null
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        };
    }
});

const router = useRouter();
const user = useUserStore();

const isOpen = ref<boolean>(false);
const isOpenSubmenu = ref<boolean>(false);
const isPopoverOpen = ref<boolean>(false);

const profileAvatarUrl = computed(() => {
    if (user.user && user.user?.profile?.avatar && user.user?.profile?.avatar?.url) {
        return user.user?.profile?.avatar?.url;
    } else {
        return '/image/placeholder.jpg';
    }
});

function userLogout() {
    user.logoutUser();
    router.go(0);
}

function getCurrentUrl(route: any) {
    return !!route.page
        ? {
              name: 'page',
              params: {
                  slug: route.internalUrl
              }
          }
        : { name: route.internalUrl };
}
</script>
<style lang="scss"></style>
