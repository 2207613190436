<template>
    <footer v-if="!$route.meta.hidePageLayout" class="relative bg-[var(--dark)] text-white pt-12 pb-8 md:pb-12">
        <div class="container">
            <div class="flex flex-col lg:flex-row gap-x-16 lg:gap-x-10 gap-y-10 pb-10">
                <div class="flex gap-x-1.5 lg:flex-col">
                    <router-link to="/" class="block w-40 mb-8" rel="noopener noreferrer">
                        <LogoIcon class="fill-[#ffc50d]" rel="noopener noreferrer" />
                    </router-link>

                    <ul class="flex gap-x-1.5 ml-auto lg:ml-0">
                        <li>
                            <a
                                href="https://www.linkedin.com/company/advisorycxo"
                                class="relative flex items-center justify-center bg-[var(--dark-text)] rounded-sm text-white w-8 h-8 text-center transition-background-color ease-&lsqb;cubic-bezier(0.46,0.03,0.52,0.96)&rsqb; duration-200 hover:text-white hover:bg-primary"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="LinkedIn Profile">
                                <LinkedInIcon class="w-3.5 h-3.5 fill-current" />
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://twitter.com/advisory_cxo"
                                class="relative flex items-center justify-center bg-[var(--dark-text)] rounded-sm text-white w-8 h-8 text-center transition-background-color ease-&lsqb;cubic-bezier(0.46,0.03,0.52,0.96)&rsqb; duration-200 hover:text-white hover:bg-primary"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Twitter Profile">
                                <TwitterIcon class="w-3.5 h-3.5 fill-current" />
                            </a>
                        </li>
                    </ul>
                </div>

                <div v-if="data?.navigation_items.length > 0" class="flex flex-wrap flex-1 min-0 gap-x-6 gap-y-2">
                    <div v-for="route in data?.navigation_items" :key="route.id" class="grow max-w-max">
                        <h3 class="text-white mb-3 md:mb-5 text-lg font-light">
                            {{ route.name }}
                        </h3>
                        <ul v-if="route.navigation_links" class="text-[#ededed] text-base font-extralight">
                            <li
                                v-for="item in route.navigation_links"
                                :key="item.internalUrl"
                                class="flex items-start gap-x-2 md:gap-x-3 lg:gap-x-2 text-[#747990] font-normal mb-2.5">
                                <img
                                    v-if="item.icon && item.icon.url"
                                    :src="item.icon.url"
                                    :alt="item.icon.url"
                                    class="w-[18px] h-[18px] fill-primary" />
                                <span
                                    v-if="item.internalUrl === null && item.externalUrl === null"
                                    class="flex-1 pointer-events-none">
                                    {{ item.name }}
                                </span>
                                <a
                                    v-else-if="item.externalUrl"
                                    rel="noopener noreferrer nofollow"
                                    :target="item.newTab ? '_blank' : ''"
                                    :href="item.externalUrl">
                                    {{ item.name }}
                                </a>

                                <template v-if="!!item.internalUrl">
                                    <router-link
                                        v-if="!!item.page"
                                        :to="{
                                            name: 'page',
                                            params: { slug: item.internalUrl }
                                        }"
                                        class="flex-1">
                                        {{ item.name }}
                                    </router-link>
                                    <router-link v-else :to="`/${item.internalUrl}`" class="flex-1">
                                        {{ item.name }}
                                    </router-link>
                                </template>
                            </li>
                        </ul>
                    </div>
                </div>

                <Newsletter class="max-w-96 md:max-w-[342px] w-full md:col-start-2 md:col-end-4 lg:col-auto" />
            </div>
            <div class="text-center lg:text-left text-base text-[#FCFCFC]">&copy; {{ currentYear }} AdvisoryCXO</div>
        </div>
    </footer>
</template>
<script setup lang="ts">
// //! Icons
// Logo
import LogoIcon from '@/assets/icons/advisorycxo-without-icon.svg';
// Socials
import LinkedInIcon from '@/assets/icons/linkedin.svg';
import TwitterIcon from '@/assets/icons/twitter.svg';

interface Props {
    data: {
        id: string | number;
        name: string;
        navigation_items: [
            {
                id: string | number;
                name: string;
                navigation_links: [
                    {
                        id: string | number;
                        name: string;
                        newTab: boolean;
                        internalUrl: string;
                        externalUrl: string;
                        icon: {
                            url: string;
                        };
                        page: {
                            slug: string | null;
                        };
                    }
                ];
            }
        ];
    };
}

withDefaults(defineProps<Props>(), {
    data: (): Props['data'] => {
        return {
            id: '',
            name: '',
            navigation_items: [
                {
                    id: '',
                    name: '',
                    navigation_links: [
                        {
                            id: '',
                            name: '',
                            newTab: false,
                            internalUrl: '',
                            externalUrl: '',
                            icon: {
                                url: ''
                            },
                            page: {
                                slug: null
                            }
                        }
                    ]
                }
            ]
        };
    }
});

const date = new Date();
const currentYear = date.getFullYear();
</script>
<style lang="scss"></style>
