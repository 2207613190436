<template>
    <div>
        <p class="font-light mb-[30px]">
            Subscribe to our <strong>weekly digest</strong> for the highlights added <strong><i>last week</i></strong> straight to
            your inbox!
        </p>

        <form @submit.prevent="onSubmit">
            <div class="flex items-start gap-x-2.5 mb-3">
                <div class="flex-1 min-w-0">
                    <div
                        class="flex items-center gap-x-3 px-3 bg-[#2F3A4C] border border-[#3C4B62] rounded-[10px] overflow-hidden">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round">
                            <rect width="20" height="16" x="2" y="4" rx="2" />
                            <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
                        </svg>

                        <input
                            v-model="email"
                            type="email"
                            v-bind="emailAttrs"
                            placeholder="Email Address"
                            required
                            class="h-10 w-full bg-transparent outline-none placeholder:text-[#E9F2FF]" />
                    </div>
                </div>
                <button class="button primary" type="submit" :class="{ 'opacity-40 cursor-default': form.isLoading }">
                    <span class="text-gray-900">
                        <template v-if="form.isLoading">Subscribing...</template>
                        <template v-else>Subscribe</template>
                    </span>
                </button>
            </div>

            <span v-if="form.isSubscribe" class="badge badge--danger">
                <span class="badge-text">Error</span>
                Subscription issue! Check email and retry
            </span>

            <span v-if="form.isError" class="badge badge--primary">
                <span class="badge-text">Success</span>
                You're set! More coming soon!
            </span>
            <span v-if="form.isSuccess" class="badge badge--primary">
                <span class="badge-text">Success</span>
                Congrats! Weekly knowledge fuel awaits!
            </span>
        </form>
    </div>
</template>
<script setup lang="ts">
import { apiUrl, token } from '@/utils/constant';
import { toTypedSchema } from '@vee-validate/zod';
import { useForm } from 'vee-validate';
import { z } from 'zod';

import { useUserStore } from '@/store/user';

const user = useUserStore();

const form = reactive({
    isLoading: false,
    isSuccess: false,
    isError: false,
    isSubscribe: false
});

const schema = z.object({
    email: z.string().min(1).email()
});

const { handleSubmit, setValues, defineField } = useForm({
    validationSchema: toTypedSchema(schema)
});

const [email, emailAttrs] = defineField('email');

const onSubmit = handleSubmit(async (values: any, { resetForm, setFieldError }: { resetForm: any; setFieldError: any }) => {
    form.isLoading = false;

    // Set errors on Fields
    setFieldError('email', 'Incorrect credentials');

    if (values) form.isLoading = true;

    try {
        const res = await fetch(`${apiUrl}/subscriptions`, {
            method: 'POST',
            body: JSON.stringify({
                email: values.email
            }),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        });

        if (!res.ok) {
            form.isLoading = false;
            form.isSuccess = false;
            form.isSubscribe = true;
        } else {
            form.isLoading = false;
            form.isSuccess = true;
            form.isError = false;
            form.isSubscribe = false;

            resetForm();
        }

        return res.json();
    } catch (e) {
        form.isError = true;
        console.log(e);
    }
});

watch(
    () => user.user,
    (value) => {
        setValues({
            email: value?.email
        });
    }
);
</script>
