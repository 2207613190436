import { User } from '@/@types/user';
import { AUTH_TOKEN } from '@/utils/constant';
import { url } from '@/utils/constant';
import { useUniversalCookies } from '@/utils/cookies';
import { useStorage } from '@vueuse/core';
import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import { jwtDecode } from 'jwt-decode';
import { acceptHMRUpdate, defineStore, skipHydrate } from 'pinia';
import { computed, ref } from 'vue';

const axiosWithCache = Axios as any;

export const useUserStore = defineStore('user', () => {
    const cookies = useUniversalCookies([AUTH_TOKEN]);
    const storage = ref(useStorage(AUTH_TOKEN, ''));
    const user = ref<Partial<User>>();

    const instance = Axios.create({
        baseURL: url,
        maxRedirects: 0,
        headers: { Authorization: `Bearer ${cookies.get(AUTH_TOKEN)}` }
    });

    //? get and set cookie token
    const token = computed({
        get: () => {
            return cookies.get(AUTH_TOKEN);
        },
        set: (value: string | undefined) => {
            if (value === undefined && cookies.get(AUTH_TOKEN)) {
                cookies.remove(AUTH_TOKEN, {
                    sameSite: 'lax',
                    path: '/'
                });
            } else if (value !== undefined) {
                const { exp } = jwtDecode(value as string);
                let date;

                if (exp) {
                    const expirationTimestamp = exp * 1000;
                    date = new Date(expirationTimestamp);
                }

                cookies.set(AUTH_TOKEN, value, {
                    sameSite: 'lax',
                    path: '/',
                    expires: date
                });
            }
        }
    });

    const axios = axiosWithCache.defaults.cache ? instance : setupCache(instance);

    const isLoggedIn = computed(() => {
        const value = token.value !== undefined && token.value !== '';
        return value;
    });

    function setToken(value: string) {
        token.value = value;
        storage.value = value;
    }

    function setUser(value: Partial<User>) {
        user.value = value;
    }

    async function fetchUser() {
        if (!isLoggedIn.value) return;

        try {
            const { data } = await axios.get('users/me');
            setUser(data);
        } catch (e) {
            console.log(e);
        }
    }

    function logoutUser() {
        user.value = undefined;
        token.value = undefined;
        storage.value = '';
    }

    return {
        user: skipHydrate(user),
        token: skipHydrate(token),
        isLoggedIn: skipHydrate(isLoggedIn),
        setToken,
        logoutUser,
        fetchUser
    } as const;
});

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
