import '@/assets/css/index.css';
import '@/assets/scss/main.scss';
import createRouter from '@/router';
import { origin } from '@/utils/constant';
import { InferSeoMetaPlugin } from '@unhead/addons';
import { createHead } from '@unhead/vue';
import 'nprogress/nprogress.css';
import { register } from 'swiper/element/bundle';
import 'swiper/swiper-bundle.css';
import { createSSRApp } from 'vue';

import App from './app.vue';

const plugins = import.meta.glob<{ default: AppPlugin }>('./plugins/*.ts') as any;

export type AppContext = Awaited<ReturnType<typeof createApp>>;
export type AppPlugin = (context: AppContext) => void | Promise<void>;

// this is a helper function to define plugins with autocompletion
export function definePlugin(plugin: AppPlugin) {
    return plugin;
}

// register Swiper custom elements
register();

export async function createApp() {
    const app = createSSRApp(App);
    const router = createRouter();
    const head = createHead({
        plugins: [InferSeoMetaPlugin()]
    });

    const route = router.currentRoute.value;
    head.push({
        templateParams: {
            schemaOrg: {
                host: origin,
                path: route.path
            }
        }
    });

    app.use(head);

    const context = { app, router, head, initialState: {} as Record<string, any> };

    if (typeof window !== 'undefined') {
        context.initialState = window.__INITIAL_STATE__ ?? {};
    }

    app.provide('app', { plugins });

    for (const path in plugins) {
        if (!Object.hasOwn(plugins, path)) continue;

        try {
            const { default: plugin } = await plugins[path]();
            await plugin(context);
        } catch (error) {
            console.error(`Error while loading plugin "${path}".`);
            console.error(error);
        }
    }

    // use router after plugin registration, so we can register navigation guards
    app.use(router);

    return context;
}
