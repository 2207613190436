import { RouteRecordRaw } from 'vue-router';

const ResourcesList = () => import('@/pages/resources/list.vue');

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'home',
        meta: {
            scrollPos: {
                top: 0,
                left: 0
            }
        },
        component: () => import('@/pages/index.vue')
    },
    //! Dynamic route
    {
        path: '/:slug',
        name: 'page',
        props: true,
        component: () => import('@/pages/page.vue')
    },
    {
        path: '/company/:slug',
        props: true,
        component: () => import('@/pages/company/index.vue'),
        children: [
            {
                path: '',
                name: 'company-overview',
                component: () => import('@/pages/company/overview.vue')
            }
        ]
    },
    {
        path: '/user/:username',
        name: 'user',
        props: true,
        component: () => import('@/pages/user/index.vue')
    },
    {
        path: '/settings',
        props: true,
        component: () => import('@/pages/settings/index.vue'),
        meta: {
            requiresAuth: true // the route you want to protect
        },
        redirect: () => {
            // the function receives the target route as the argument
            // a relative location doesn't start with `/`
            // or { path: 'settings-profile'}
            return '/settings/profile';
        },
        children: [
            {
                path: 'profile',
                name: 'settings-profile',
                component: () => import('@/pages/settings/profile.vue')
            },
            {
                path: 'experience',
                name: 'settings-experience',
                component: () => import('@/pages/settings/experience.vue')
            },
            {
                path: 'company',
                name: 'settings-company',
                component: () => import('@/pages/settings/company.vue')
            }
        ]
    },
    {
        path: '/resources',
        component: () => import('@/pages/resources/index.vue'),
        children: [
            {
                path: '',
                name: 'resources',
                component: ResourcesList,
                children: [
                    //! skills and interest
                    {
                        path: 'skills/:skill',
                        name: 'skill',
                        component: ResourcesList
                    },
                    {
                        path: 'interests/:interest',
                        name: 'interest',
                        component: ResourcesList
                    },
                    //! topics and tags(old routes)
                    //? this code is needed to avoid 404 error for old routes
                    {
                        path: 'topics/:topic',
                        name: 'oldTopic',
                        component: ResourcesList
                    },
                    {
                        path: 'tags/:tag',
                        name: 'oldTag',
                        component: ResourcesList
                    },

                    //! content type
                    {
                        path: 'articles',
                        name: 'articles',
                        component: ResourcesList,
                        meta: {
                            type: 'article'
                        }
                    },
                    {
                        path: 'books',
                        name: 'books',
                        component: ResourcesList,
                        meta: {
                            type: 'book'
                        },
                        children: [
                            {
                                path: 'skills/:skill',
                                name: 'book-skill',
                                component: ResourcesList
                            },
                            {
                                path: 'interests/:interest',
                                name: 'book-interest',
                                component: ResourcesList
                            },
                            {
                                path: ':topic',
                                name: 'book-topic',
                                component: ResourcesList
                            },
                            {
                                path: ':tag',
                                name: 'book-tag',
                                component: ResourcesList
                            }
                        ]
                    },
                    {
                        path: 'movies',
                        name: 'movies',
                        component: ResourcesList,
                        meta: {
                            type: 'movie'
                        }
                    },
                    {
                        path: 'podcasts',
                        name: 'podcasts',
                        component: ResourcesList,
                        meta: {
                            type: 'podcast'
                        },
                        children: [
                            {
                                path: 'skills/:skill',
                                name: 'podcast-skill',
                                component: ResourcesList
                            },
                            {
                                path: 'interests/:interest',
                                name: 'podcast-interest',
                                component: ResourcesList
                            },
                            {
                                path: ':topic',
                                name: 'podcast-topic',
                                component: ResourcesList
                            },
                            {
                                path: ':tag',
                                name: 'podcast-tag',
                                component: ResourcesList
                            }
                        ]
                    },
                    {
                        path: 'tools',
                        name: 'tools',
                        component: ResourcesList,
                        meta: {
                            type: 'tool'
                        },
                        children: [
                            {
                                path: 'skills/:skill',
                                name: 'tool-skill',
                                component: ResourcesList
                            },
                            {
                                path: 'interests/:interest',
                                name: 'tool-interest',
                                component: ResourcesList
                            },
                            {
                                path: ':topic',
                                name: 'tool-topic',
                                component: ResourcesList
                            },
                            {
                                path: ':tag',
                                name: 'tool-tag',
                                component: ResourcesList
                            }
                        ]
                    },
                    {
                        path: 'videos',
                        name: 'videos',
                        component: ResourcesList,
                        meta: {
                            type: 'video'
                        }
                    }
                ]
            },
            {
                path: ':type/:slug',
                name: 'post',
                component: () => import('@/pages/resources/post.vue')
            },
            {
                path: 'search',
                name: 'search',
                component: () => import('@/pages/resources/search.vue')
            }
        ]
    },
    {
        path: '/resource-recommendation',
        name: 'resource-recommendation',
        component: () => import('@/pages/resource-recommendation.vue'),
        meta: {
            requiresAuth: true // the route you want to protect
        }
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/pages/contact.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/login.vue'),
        meta: {
            hidePageLayout: true
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/pages/register.vue'),
        meta: {
            hidePageLayout: true
        }
    },
    {
        path: '/cookies',
        name: 'why-cookies-are-important',
        component: () => import('@/pages/cookies.vue'),
        meta: {
            hidePageLayout: true
        }
    },
    //? will use if needed
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('@/pages/404.vue'),
        meta: {
            hidePageLayout: true
        }
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/pages/404.vue'),
        meta: {
            hidePageLayout: true
        }
    }
];

export default routes;
