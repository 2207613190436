import { token, url } from '@/utils/constant';
import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import qs from 'qs';

const axiosWithCache = Axios as any;

const instance = Axios.create({
    baseURL: url,
    maxRedirects: 0,
    headers: { Authorization: `Bearer ${token}` },
    paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'brackets' });
    }
});

const axios = axiosWithCache.defaults.cache ? instance : setupCache(instance);

export async function fetchAPI(path: string = '', params: object = {}) {
    try {
        const { data } = await axios.get(path, { params });

        return data;
    } catch (error: unknown) {
        if (error instanceof Error) throw new Error(error.message);
    }
}
